body{
  background-color: #131b28;
  color: white;
}

.dashboard{
  display: grid;
  grid-template: repeat(5,1fr) / repeat(5,1fr) ;
  gap: 10px;
  padding: 10px;
}

.top-bar{
  grid-column: 2 / 6;
  background-color: #192134;
  border-radius: 6px;
  padding: 15px;
}

.left-bar{
  grid-column: 1 / 2;
  grid-row: 1 / 4;
  background-color: #192134;
  border-radius: 6px;
  padding: 15px;
}

.main{
  grid-column: 2 / 6;
  grid-row: 2 / 4;
  border-radius: 6px;
}

.bottom-bar{
  grid-column: 1 / 6;
  grid-row: 4 / 6;
  border-radius: 6px;
  display: flex;
  width: 100%
}


// =======================================================
//             Top Bar
// =======================================================

@import "../../Components/TopBar/topbar";

// =======================================================
//             left Bar
// =======================================================

@import "../../Components/LeftBar/Leftbar";

// =======================================================
//             Main
// =======================================================

@import "../../Components/Main/main";

// =======================================================
//             Bottom Bar
// =======================================================

@import "../../Components/BottomBar/bottombar";

// ------------------
//  Media Quiry
// ------------------

@media screen and (max-width:1000px) {
  .dashboard{
    display: flex;
    flex-direction: column;
  }
}


@media screen and (max-width:1000px) {
  .left-bar{
    display: flex;
    justify-content: center;
  }
}