
.list-boxs{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 15px;
  margin-top: 15px;
}

.consumption{
  margin-top: 20px !important;
}

// ------------------
//  Media Quiry
// ------------------

@media screen and (max-width:750px) {
  .list-boxs{
    flex-direction: column;
  }
}