.card{
  width: 100%;
  display: flex;
  justify-content: space-between; 
}
.card-top{
  display: flex;
  justify-content: space-between;
}

.card-top:nth-child(1){
  margin-right: 10px;
}

.card-left{
  width: 50% !important;
  border-radius: 6px;
  background-color: #192134;
  margin-right: 10px;
  padding: 15px;
}

.card-right{
  width: 50%;
  border-radius: 6px;
  background-color: #192134;
  padding: 15px;
}

.Polar-chart{
  width: 100% !important;
  height: 100% !important;
}