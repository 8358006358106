// Menu Toggle
ul{
  margin: 0;
  padding: 0;
  list-style: none;
  li{
    margin-top: 15px;
    list-style: none;
  }
}
// title
.title-list-li{
  color: #595f6e;
}
// buttons
.button-menu{
  background-color: #24365e !important;
  border-radius: 20px !important;
  color: white !important;
  padding: 5px 10px !important;
}
// menu list
.menu-list{
  width: fit-content !important;
}


// ------------------
//  Media Quiry
// ------------------

@media screen and (max-width:1000px) {
  ul{
    margin-top: 20px;
  }
}