.card-dashboard{
  background: #192134 !important;
}

// ------------------
//  Media Quiry
// ------------------

// tablet 
@media screen and (max-width:1000px) {
  .card-dashboard{
    text-align: center;
  }
}

// phone

@media screen and (max-width:750px) {
    .card-main{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .card-dashboard{
      width: 100%;
    }
    .card-dashboard:first-child{
      margin-bottom: 15px;
    }
}